.sidebar {
  position: fixed;
  float: left;
  height: 100%;
  width: 18%;
  top: 66px;
  border-right: 1px solid #ebedf8;
  z-index: 1;
}

@media (max-width: 900px) {
  .sidebar {
    position: relative;
    float: none;
    width: 100%;
    height: auto;
    top: 0;
    padding: 0 40px;
  }
}
