html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  background-color: #fafbfc;
}

#root {
  box-sizing: border-box;
}

div,
span,
a {
  box-sizing: border-box;
}

.wrapper {
  height: 100%;
  font-family: Lato, sans-serif;
  font-weight: normal;
  font-size: 15px;
  box-sizing: inherit;
}

.clearfix::after {
  display: block;
  content: '';
  clear: both;
}
