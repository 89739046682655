.main-content {
  width: 76%;
  float: right;
  margin-top: 87px;
  padding-bottom: 20px;
}

@media (max-width: 900px) {
  .main-content {
    width: 100%;
    padding: 0 40px;
    margin: 20px 0;
    float: none;
  }
}
